<template>
  <b-card>
      <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form class="mt-2">

        <b-row>
          <b-col cols="12" md="6" lg="6">
            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Code')" label-for="code">
                  <validation-provider #default="{ errors }" rules="required" name="Code">
                    <b-form-input id="code" v-model="code" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Color')" label-for="color">
                  <validation-provider #default="{ errors }" rules="required" name="Color">
                    <b-form-input id="color" v-model="color" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group
                  label=""
                  label-for="contact-options"
                  label-class="mb-1">
                  <div class="demo-inline-spacing">
                    <b-form-checkbox
                      v-model="isActive"
                      class="custom-control-primary">
                      {{ $t('Active')}}
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-form-group :label="$t('Title')" label-for="title">
                  <validation-provider #default="{ errors }" rules="required" name="title">
                    <b-form-input id="title" v-model="title" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-form-group :label="$t('Source Codes')" label-for="sourceCodes">
                  <validation-provider #default="{ errors }" rules="required" name="sourceCodes">
                    <b-form-textarea
                      id="textarea-auto-height"
                      v-model="sourceCodes"
                      class="source-input"
                      placeholder="Tall textarea"
                      rows="8"
                      no-auto-shrink
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          
          <b-col cols="12" md="6" lg="6">
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <label class="mb-0">{{ $t('Picture') }}</label>
                <div class="bg-light-primary rounded-top text-center mt-1 banner-img-box">
                  <b-img :src="pictureUrl" class="responsive"/>
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-primary btn-image-update"
                    class="mt-0 mt-md-2"
                    @click="openUpload()"
                  >
                    <feather-icon icon="PaperclipIcon" class="mr-25" />
                    <span>{{ !pictureUrl? $t('Add') : $t('Change') }}</span>
                  </b-button>
                </div>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <label class="mb-0">{{ $t('Picture Logo') }}</label>
                <div class="bg-light-primary rounded-top text-center mt-1 banner-img-box">
                  <b-img :src="logoUrl" class="responsive"/>
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-primary btn-image-update"
                    class="mt-0 mt-md-2"
                    @click="openLogoUpload()"
                  >
                    <feather-icon icon="PaperclipIcon" class="mr-25" />
                    <span>{{ !logoUrl? $t('Add') : $t('Change') }}</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <save-button 
              :onClickSaveAndClose="saveItem.bind(this, true)"
              :onClickSave="saveItem.bind(this, false)"
              />
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max,
} from '@validations'
import { createHelpers } from 'vuex-map-fields';
import Ripple from 'vue-ripple-directive'
import { getDataVersion } from '@/store/ancestry-data/utils'

import SaveButton from '@/components/SaveButton.vue';

import ApiService from '@/common/api.service'
// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// Alert
import AlertService from '@/common/alert.service'

const { mapFields } = createHelpers({
  getterType: 'presetFarmersHgsManagement/getFarmersHgField',
  mutationType: 'presetFarmersHgsManagement/updateFarmersHgField',
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    SaveButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      min,
      max,
      pictureForm: new FormData(),
    }
  },
  methods: {
    saveItem(isClose) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const query = this.$store.getters['presetFarmersHgsManagement/getFarmersHgForm'];
          if (query.dataVersion !== getDataVersion(this.$store)) {
            AlertService.warning(this, 'Data version does not match selected version');
            return;
          }

          this.$store.dispatch('presetFarmersHgsManagement/savePresetFarmersHg', query)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                })

                if (isClose) {
                  this.$router.go(-1)
                } else {
                  this.$store.commit('presetFarmersHgsManagement/setFarmersHgField', response.result);
                  this.$router.push({ name: 'manage-preset-farmsers-hgs-save', params: { id: response.result.id } });
                }
              } else {
                AlertService.error(this, response.message)
              }
            });
        }
      })
    },

    openUpload() {
      if (this.id === 0) {
        AlertService.error(this, 'Please first save and then try to upload picture.');
        return;
      }

      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/png, image/jpeg'
      fileInput.addEventListener('change', event => {
        this.pictureForm.set('file', event.target.files[0]);
        this.pictureForm.set('folderName', 'farmers-hgs-images');

        ApiService.uploadImage('UploadImage', this.pictureForm, this)
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.message,
                  icon: 'CheckCircleIcon',
                  variant: 'info',
                },
              });
              this.pictureUrl = response.result;
            } else {
              AlertService.error(this, response.message)
            }
          });
      })
      fileInput.click();
    },

    openLogoUpload() {
      if (this.id === 0) {
        AlertService.error(this, 'Please first save and then try to upload picture.');
        return;
      }

      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/png, image/jpeg'
      fileInput.addEventListener('change', event => {
        this.pictureForm.set('file', event.target.files[0]);
        this.pictureForm.set('folderName', 'farmers-hgs-images');
        this.pictureForm.set('subFolderName', 'logo');

        ApiService.uploadImage('UploadImage', this.pictureForm, this)
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.message,
                  icon: 'CheckCircleIcon',
                  variant: 'info',
                },
              });
              this.logoUrl = response.result;
            } else {
              AlertService.error(this, response.message)
            }
          });
      })
      fileInput.click();
    },
  },
  computed: {
    ...mapFields([
      'id',
      'isActive',
      'code',
      'title',
      'sourceCodes',
      'pictureUrl',
      'logoUrl',
      'color',
    ]),
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.source-input {
    box-sizing: border-box;
    margin: 0px;
    outline: 0px;
    padding: 1em;
    height: calc(100% - 3em);
    min-height: 3em;
    width: 100%;
    overflow: auto;
    color: inherit;
    font-size: 0.7em;
    white-space: pre;
}

.banner-img-box {
  height: 90%;
}

.banner-img-box img {
  height: 350px;
  width: auto;
}

.btn-image-update {
  position: absolute !important;
  bottom: 0px;
  right: 15px;
}
</style>
